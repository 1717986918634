import styled from 'styled-components'

export const Mbr = styled.br`
  display: none;
  @media (max-width: 40rem){
    display: inline;
  }
`

export const Dbr = styled.br`
  @media (max-width: 40rem){
    display: none;
  }
`