import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { ll } from '../styles/ll'
import { fullWidth, pageMaxWidth } from '../styles/page-max-width'

const Outer = styled.div`
${pageMaxWidth}
`

const Inner = styled.div`
  padding: ${ll(28)} 0;
  display: flex;
  gap: ${ll(28)};
  position: relative;
  @media (max-width: 50rem){
    flex-direction: column;
    padding-top: 0;
    gap: ${ll(10)}
  }
`

const Head = styled.div`
font-size: ${ll(14)};
line-height: normal;
writing-mode: vertical-rl;
border-block: 1px solid currentColor;
letter-spacing: 0.2em;
padding: ${ll(14)};
@media (max-width: 50rem){
  background-color: #003DAA;
  font-size: ${ll(15)};
font-weight: 700;
writing-mode: horizontal-tb;
color: white;
padding: ${ll(2)};
margin-top: ${ll(-10)};
}
`

const List = styled.div`
display: grid;
grid-template-columns: auto auto;
align-items: center;
gap: ${ll(16)};
@media (max-width: 50rem){
  grid-template-columns: 1fr;
  padding-inline: ${ll(5)};
  gap: ${ll(16)};
}
`

const Item = styled.a`
display: grid;
grid-template-columns: subgrid;
grid-column: 1 / -1;
transition: opacity 0.4s;
&:hover {
  opacity: 0.5;
}
@media (max-width: 50rem){
  display: block;
  
}
`

const Cat = styled.div`
font-size: ${ll(12)};
line-height: normal;
background-color: #0035A1;
padding: ${ll(6)} ${ll(20)};
border-radius: 100rem;
color: white;
height: fit-content;
@media (max-width: 50rem){
  font-size: ${ll(10)};
line-height: normal;
font-weight: 700;
display: inline-block;
padding: ${ll(0)} ${ll(10)};
}
`

const Text = styled.div`
font-size: ${ll(14)};
line-height: normal;
height: fit-content;
align-self: center;
margin-left: ${ll(14)};
@media (max-width: 50rem){
  font-size: ${ll(12)};
line-height: normal;
margin-left: 0;
margin-top: 0.25rem;
}
`

const Button = styled.a`
display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: ${ll(12)};
line-height: normal;
background-color: #0035A1;
color: white;
padding: ${ll(6)} ${ll(16)};
margin-block: ${ll(28)};
@media (max-width: 50rem){
  bottom: auto;
  top: ${ll(-35)};
  padding: ${ll(3)} ${ll(14)};

  
}

`

interface Post {
  title: string;
  content: string;
  url: string;
  date: string;
  categories: string[];
}

type NewsSectionProps = {

}
export const NewsSection: FunctionComponent<NewsSectionProps> = () => {
  return <Outer>
    <Inner>
      <Head>
        お知らせ
      </Head>
      <List>
        {
          (JSON.parse(document.getElementById('news-data')?.textContent ?? '[]') as Post[]).map((p, i) => {
            return <Item key={i} href={p.url}>
              <Cat>
                {p.categories[0]}
              </Cat>
              <Text>
                {p.title}
              </Text>
            </Item>
          })
        }
      </List>
      <Button href="/archive-all/">
        一覧を見る
      </Button>
    </Inner>
  </Outer>
}