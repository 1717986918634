import { FunctionComponent, useEffect } from 'react'
import {throttle} from 'throttle-debounce'

type RestoreScrollProps = {

}
export const RestoreScroll: FunctionComponent<RestoreScrollProps> = () => {
  useEffect(() => {
    const listener = throttle(200, () => {
      history.replaceState({...history.state, scrollY: window.scrollY}, "")
    }, {})
    window.addEventListener('scroll', listener)
    return () => {
      window.removeEventListener('scroll', listener)
    }
  }, [])
  useEffect(() => {
    const listener = async () => {
      const scrollY = history.state?.scrollY
      setTimeout(async () => {
        await Promise.all(Array.from(document.querySelectorAll('img')).map(img => new Promise<void>(res => {
          if(img.complete){
            res()
          } else {
            img.addEventListener('load', () => res())
          }
        })))
        if(typeof scrollY === 'number'){
          window.scrollTo({top: scrollY ?? 0})
        } else {
          if(location.hash){
            document.querySelector(location.hash)?.scrollIntoView()
          }
        }
      }, 500)
    }
    window.addEventListener('popstate', listener)
    listener()
    return () => {
      window.removeEventListener('popstate', listener)
    }
  }, [])
  useEffect(() => {
    const listener = () => {
      console.log('hashchange', document.querySelector(location.hash))
      if(location.hash){
        if(location.hash === '#'){
          window.scrollTo({top: 0, behavior: 'smooth'})
        } else {
          document.querySelector(location.hash)?.scrollIntoView({behavior: 'smooth'})
        }
      }
    }
    window.addEventListener('hashchange', listener)
    return () => {
      window.removeEventListener('hashchange', listener)
    }
  })
  return null
}