import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { ll } from '../styles/ll'
import { Image } from '../components/image'


const Outer = styled.div`
  background-color: #FFFFF1;
  padding-top: ${ll(48)};
  padding-bottom: ${ll(0)};
  @media (max-width: 50rem){
    padding-top: ${ll(20)};
    margin-top: ${ll(0)};
    padding-bottom: 0;
  }
`

const Inner = styled.div`
  max-width: ${ll(1100)};
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding: ${ll(0)} ${ll(60)};
  @media (max-width: 50rem){
    padding: 0 ${ll(10)};
  }
`


const H1 = styled.div`
font-size: ${ll(38)};
line-height: normal;
text-align: center;
position: relative;
font-weight: 700;
@media (max-width: 50rem){
  font-size: ${ll(18)};
line-height: normal;
font-weight: 700;
}
&::after {
  content: '';
  display: block;
  height: ${ll(2)};
  width: ${ll(490)};
  margin-left: auto;
  margin-right: auto;
  background-color: #7046B3;
  @media (max-width: 50rem){
    width: 95%;
    margin-top: ${ll(6)};
    
  }
}
`

const Img = styled.div`
  margin-top: ${ll(20)};
  img {
    width: 100%;
    height: auto;
    display: block;
  }
`
const Text = styled.div`
font-size: ${ll(16)};
line-height: normal;
margin-top: ${ll(24)};
margin-bottom: ${ll(24)};
max-width: ${ll(691)};
margin-inline: auto;
@media (max-width: 50rem){
  font-size: ${ll(15)};
line-height: normal;
margin-top: ${ll(12)};
line-height: 1.5;
}
strong {
  color: #FF4F6B;
}
`
const Img1 = styled(Img)`
  max-width: ${ll(840)};
  margin-left: auto;
  margin-right: auto;
  margin-top: ${ll(30)};
  margin-bottom: ${ll(75)};
`
const Hr1 = styled.div`
  margin-top: ${ll(-20)};
  display: grid;
  grid-template-columns: ${ll(244)} ${ll(660)};
  gap: ${ll(33)};
  justify-content: center;
  @media (max-width: 50rem){
    grid-template-columns: 1fr;
  }
`

const Hr1I = styled.div`
font-size: ${ll(16)};
line-height: normal;
@media (max-width: 50rem){
  
  font-size: ${ll(15)};
line-height: normal;
line-height: 1.3;
padding-inline: ${ll(20)};
&:nth-child(1) {
  order: 10;
}
}
  img {
    display: block;
    width: ${ll(244)};
    height: auto;
    margin-top: ${ll(80)};
    @media (max-width: 50rem){
      width: 60%;
      margin-top: ${ll(0)};
      margin-left: auto;
      margin-right: auto;
    }
  }
`

type OmoiSectionProps = {

}
export const OmoiSection: FunctionComponent<OmoiSectionProps> = () => {
  return <Outer>
  <Inner>
    <H1>
    レナテックの想い
    </H1>
    <Img1>
      <Image src="top/e1.webp" />
    </Img1>
    <Text>
        病気は、突然発症するものではありません。<br />
        病気と健常との間に病気ではないけれど健康でもない「未病」の状態があります。<br />
        <br />
        どのような病気でもこの未病の状態で発見できれば、治療はもっと簡単にできるはずです。<br />
        その考えにもとづき、私たちは微量元素のバランスから未病の状態を見つける検査の研究を行っています。<br />
        <br />
        まず、最初にがんの可能性を早期の段階でも判定することができる「がんリスク検査」を開発し、現在多くの皆様にご利用いただいております。<br />
        また、この度は新たに認知症の未病状態であるMCI（軽度認知障害）の状態を判定する「認知リスク検査」を開発いたしました。<br />
        <br />
        がんも認知症も社会問題として、大きな影を落とす疾病です。<br />
        早期発見・早期治療がたいへん重要です。
    </Text>
  </Inner>
    
  </Outer>
}
