import React, { ComponentProps } from 'react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';

const Img = styled.img`
  display: block;
`

export const resolveImagePath = (path: string) => {
  return `/wp-content/themes/metallo-balance/img/${path.replace(/^\//, '')}`
}
export type ImageProps = {
  src: string
} & ComponentProps<'img'>
export const Image: FunctionComponent<ImageProps> = (props) => {
  return <Img {...props} src={`/wp-content/themes/metallo-balance/img/${props.src.replace(/\..*$/, '')}.webp`}/>
}