import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { ll } from '../styles/ll'
import { pageMaxWidth } from '../styles/page-max-width'

const Outer = styled.div`
  background-color: #0035A1;
  @media (max-width: 50rem){
    display: none;
  }
`

const Inner = styled.div`
  ${pageMaxWidth}
  padding-top: ${ll(19)};
  padding-bottom: ${ll(20)};
  display: flex;
  justify-content: center;
  gap: ${ll(36)};
  color: white;
  @media (max-width: 50rem){
    
  }
`

const Item = styled.a`
  display: block;
  font-size: ${ll(18)};
  line-height: normal;
  font-weight: 900;
transition: opacity 0.4s;
&:hover {
  opacity: 0.5;
}
  @media (max-width: 50rem){
    
  }
`

const Icon = styled.span`
  font-family: 'FontAwesome';
  @media (max-width: 50rem){
    
  }
`

type MenuBarProps = {

}
export const MenuBar: FunctionComponent<MenuBarProps> = () => {
  return <Outer>
    <Inner>
      <Item href="/"><Icon></Icon> ホーム</Item>
      <Item href="/details"><Icon></Icon> メタロ・バランス検査について</Item>
      <Item href="/faq"><Icon></Icon> よくあるご質問</Item>
      <Item href="/access"><Icon></Icon> 検査会場へのアクセス</Item>
      <Item href="/entry"><Icon></Icon> 検査申し込み</Item>
    </Inner>

  </Outer>
}