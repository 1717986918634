import { css } from 'styled-components'

export const pageMaxWidth = css`
  max-width: var(--page-max-width);
  padding-left: var(--page-margin);
  padding-right: var(--page-margin);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  container-type: inline-size;
`

export const absoluteFullWidth = css`
  width: 100vw;
  margin-inline: calc((50vw - 50cqw - var(--page-margin)) * -1);
`

export const fullWidth = css`
  width: 100vw;
  margin-inline: calc((50vw - 50cqw) * -1);
`