import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Image } from '../components/image'
import { pageMaxWidth } from '../styles/page-max-width'
import { ll } from '../styles/ll'
import { responsibleSwap } from '../styles/responsible-swap'
import { Dbr, Mbr } from '../components/br'

const Outer = styled.div`
  background-color: #EFEDFF;
  padding-top: ${ll(134)};
  padding-bottom: ${ll(84)};
  @media (max-width: 50rem){
    padding-top: ${ll(100)};
  }
`

const Inner = styled.div`
  background-color: #fff;
  border-radius: ${ll(8)};
  max-width: ${ll(980)};
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding: ${ll(140)} ${ll(60)} ${ll(40)};
  @media (max-width: 50rem){
    margin-inline: ${ll(10)};
    padding-inline: ${ll(10)};
    padding-top: ${ll(80)};
  }
`

const Head = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: ${ll(-45)};
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  @media (max-width: 50rem){
    margin-left: auto;
    margin-right: auto;
  }
  img {
    display: block;
    width: ${ll(450)};
    height: auto;
    @media (max-width: 50rem){
      width: 95%;
    margin-left: auto;
    margin-right: auto;
    }
  }
`

const H1 = styled.div`
font-size: ${ll(38)};
line-height: normal;
text-align: center;
position: relative;
font-weight: 700;
@media (max-width: 50rem){
  font-size: ${ll(18)};
line-height: normal;
font-weight: 700;
}
&::after {
  content: '';
  display: block;
  height: ${ll(2)};
  width: ${ll(490)};
  margin-left: auto;
  margin-right: auto;
  background-color: #7046B3;
  @media (max-width: 50rem){
    width: 95%;
    margin-top: ${ll(6)};
    
  }
}
`

const Text = styled.div`
font-size: ${ll(16)};
line-height: normal;
margin-top: ${ll(24)};
max-width: ${ll(691)};
margin-inline: auto;
@media (max-width: 50rem){
  font-size: ${ll(15)};
line-height: normal;
margin-top: ${ll(12)};
line-height: 1.5;
}
strong {
  color: #FF4F6B;
}
`

const Img = styled.div`
  margin-top: ${ll(20)};
  @media (max-width: 50rem){
    
  }
  img {
    width: 100%;
    height: auto;
    display: block;
    ${responsibleSwap}
  }
`

const Img1 = styled(Img)`
  max-width: ${ll(800)};
  margin-left: auto;
  margin-right: auto;
  margin-top: ${ll(40)};
  margin-bottom: ${ll(75)};
  @media (max-width: 50rem){
    margin-top: ${ll(0)};
    margin-bottom: ${ll(10)};
    
  }
`
const Img2 = styled(Img)`
  max-width: ${ll(635)};
  margin-left: auto;
  margin-right: auto;
  margin-top: ${ll(20)};
  margin-bottom: ${ll(75)};
  @media (max-width: 50rem){
    margin-bottom: ${ll(65)};
    
  }
`
const Img3 = styled(Img)`
  max-width: ${ll(980)};
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-top: 0;
  @media (max-width: 50rem){
    width: 60%;
    margin-bottom: ${ll(50)};
  }
`
const Img5 = styled(Img)`
  max-width: ${ll(980)};
  margin-left: auto;
  margin-right: auto;
  margin-top: ${ll(10)};
  margin-bottom: ${ll(75)};
  position: relative;
  @media (max-width: 50rem){
    width: 100%;
    margin-top: ${ll(30)};
  }
`

const ListHead = styled.div`
  max-width: ${ll(710)};
  line-height: normal;
  padding: ${ll(5)};
  margin-top: ${ll(40)};
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  background-color: #E7E7E7;
  @media (max-width: 50rem){
    font-size: ${ll(15)};
  line-height: normal;
  font-weight: 700;
  margin-top: ${ll(10)};
  padding: ${ll(2)};
  }
`

const List = styled.div`
max-width: ${ll(691)};
margin-top: ${ll(32)};
margin-left: auto;
margin-right: auto;
display: flex;
flex-direction: column;
gap: ${ll(19)};
@media (max-width: 50rem){
  margin-top: ${ll(10)};
  gap: ${ll(4)};
}
`

const ListItem = styled.div`
display: flex;
align-items: center;
white-space: nowrap;
gap: ${ll(12)};
@media (max-width: 50rem){
  flex-direction: column;
  white-space: normal;
  align-items: flex-start;
  gap: ${ll(8)};
}
`

const ListItemHead = styled.div`
font-size: ${ll(19)};
line-height: normal;
padding: ${ll(0)} ${ll(10)};
background-color: #00CE9E;
color: white;
@media (max-width: 50rem){
  font-size: ${ll(16)};
line-height: normal;
font-weight: 700;
}
`

const ListText = styled.div`
font-size: ${ll(16)};
line-height: normal;
@media (max-width: 50rem){
  font-size: ${ll(14)};
line-height: normal;
  
}
`

const Text2 = styled.div`
font-size: ${ll(25)};
line-height: normal;
font-weight: 700;
margin-top: ${ll(-40)};
text-align: center;
@media (max-width: 50rem){
  font-size: ${ll(18)};
line-height: normal;
font-weight: 700;
line-height: 1.4;
}

strong {
  color: #FF4F6B;
}
`

const Button = styled.a`
  font-size: ${ll(32.2223014831543)};
  line-height: normal;
  background-color: #FF4F6B;
  font-weight: 700;
margin-top: ${ll(20)};
display: flex;
padding: ${ll(14)} ${ll(70)};
align-items: center;
color: white;
width: fit-content;
margin-left: auto;
margin-right: auto;
border-radius: 100rem;
transition: opacity 0.4s;
&:hover {
  opacity: 0.5;
}
@media (max-width: 50rem){
  font-size: ${ll(18)};
line-height: normal;
font-weight: 700;
padding: ${ll(12)} ${ll(0)};
width: 90%;
text-align: center;
margin-top: ${ll(10)};
justify-content: center;

}
`

const Text3 = styled.div`
  font-size: ${ll(14)};
  line-height: normal;
  margin-top: ${ll(20)};
  text-align: center;
  @media (max-width: 50rem){
    font-size: ${ll(11)};
line-height: normal;
margin-top: ${ll(10)};
text-align: left;
  }
`

const Icon = styled.div`
  font-family: 'FontAwesome';
`

const Img4 = styled.div`
  position: absolute;
  bottom: ${ll(-84)};
  right: ${ll(-20)};
  @media (max-width: 50rem){
    position: relative;
    inset: auto;
    margin-top: ${ll(-20)};
    
  }
  img {
    display: block;
    width: ${ll(292.97)};
    height: auto;
    @media (max-width: 50rem){
      width: 63%;
      margin-left: auto;
      margin-right: auto;
    }
  }
`

const Text6 = styled(Text)`
  margin-top: 0;
`

const C1 = styled.div`
  display: grid;
  grid-template-columns: ${ll(519 / 2)} 1fr;
  gap: ${ll(20)};
  margin-top: ${ll(40)};
  @media (max-width: 50rem){
    grid-template-columns: 1fr;
    grid-auto-flow: dense;
  }
`

const L1 = styled.div`
width: ${ll(720)};
box-sizing: border-box;
padding: ${ll(9)};
background-color: #F0F6F8;
margin-left: auto;
margin-right: auto;
margin-top: ${ll(20)};
@media (max-width: 50rem){
  width: 100%;
}
`

const L1Inner = styled.div`
  padding: ${ll(26)};
  border: 1px dashed #B9B9B9;
`
const L1Text = styled.div`
font-size: ${ll(14)};
line-height: normal;
text-align: center;
a {
  color: #0035A1;
  text-decoration: underline;
}
`

type GSectionProps = {

}
export const GSection: FunctionComponent<GSectionProps> = () => {
  return <Outer id="g">
    <Inner>
      <Head>
        <Image src="top/g1.webp" />
      </Head>
      <H1>
        メタロ・バランス がんリスク検査とは
      </H1>
      <Text>
        血液中の亜鉛など17種の微量元素濃度を測定し、健康な人とがんである人の微量元素濃度のバランスの違いを統計学的手法で解析することで、現在がんであるリスク（危険度）をスクリーニング（ふるい分け）評価します。 早期のがんリスクにも対応した検査で、<strong>自覚症状が出る前・より早い段階でのがんの発見を目的とする全く新しいがんリスク検査</strong>です。採血のみの簡便な検査で、事前準備も一般的な採血と変わりません。
      </Text>
      <ListHead>
        対応がん種
      </ListHead>
      <List>
        <ListItem>
          <ListItemHead>
            男性6種類
          </ListItemHead>
          <ListText>
            大腸がん、胃がん、肺がん、すい臓がん、肝臓がん、前立腺がん
          </ListText>
        </ListItem>
        <ListItem>
          <ListItemHead>
            女性9種類
          </ListItemHead>
          <ListText>
            大腸がん、胃がん、肺がん、すい臓がん、肝臓がん、乳がん、子宮頸がん、子宮体がん、卵巣がん
          </ListText>
        </ListItem>
      </List>
      <Img1>
        <Image src="top/g2.webp" />
        <Image src="top/d4m.webp" />
      </Img1>
      <H1>
        世界初のがんリスク検査
      </H1>
      <Text>
        血液中の17種類の元素濃度を調べ、その濃度バランスから、がんにかかっているリスクを調べるという世界初のがんリスク検査（米国・英国・ドイツ・フランス・スイスなど特許取得済）です。<br />
        この検査は、経済産業省の研究助成金を受けて、株式会社レナテックと神奈川県立がんセンター、千葉県がんセンターとの共同研究で生まれ、2019年から実用化した新しい検査です。 
      </Text>
      <Img2>
        <Image src="top/g3.webp" />
        <Image src="top/g3.webp" />
      </Img2>
      <H1>
        メタロ・バランス がんリスク検査の原理
      </H1>
      <C1>
        <Img3>
          <Image src="top/g4-1.webp" />
          <Image src="top/g4-1.webp" />
        </Img3>
        <Text6>
          株式会社レナテックでは、「体調に変異を来すと血清中の微量元素濃度が変化する」という学説をもとに、がんにおいても微量元素濃度が変化するに違いないとの仮説を立てました。<br /><br />
          そして共同研究先の千葉県がんセンター研究所および神奈川県立がんセンター臨床研究所から提供された「がん罹患者」「健常者」の血清を使って、 がん罹患時に変化する微量元素を特定する（Zn, Cu, Fe, Se, P, Mg, Rb, Moなど）と共に、微量元素濃度を安定的に低コストで測定する技術を確立したものです。<br /><br />
          メタロ・バランス検査は、受検者の方の血液中の微量元素等の濃度をがん罹患者のものと比較解析することで、その類似度合いをもって、がんを発症している可能性（がんリスク）とするものです。
          <L1>
            <L1Inner>
              <L1Text>
                元神奈川県立がんセンター　がん予防情報研究部部長　岡本　直幸<br />
                日本亜鉛栄養治療研究会「亜鉛栄養治療」Vol.10 No.1 2019　掲載論文<br />
                <br />
                <a href="https://metallo-balance.net/wp-content/themes/metallo-balance/metallo-balance.pdf" target="_blank" rel="noopener noreferrer">『血中微量元素を用いた新たな「がんリスク」スクリーニング法の開発』 PDF</a>
              </L1Text>
            </L1Inner>
          </L1>
        </Text6>
      </C1>
      <Img5>
        <Image src="top/g4-2.webp" />
        <Image src="top/g4m-3.webp" />
      </Img5>
      <Text2>
        認知リスク検査と同時に受けると<Mbr /><strong>採血も１回のみ</strong>で簡単<Dbr />
        <strong>特別割引価格</strong>でご提供！
      </Text2>
      <Button href="/entry">
        検査申し込みはこちら <Icon></Icon>
      </Button>
      <Text3>
        ※検査会場によっては、検査実施していない場合がございます。あらかじめご了承ください。
      </Text3>
    </Inner>
  </Outer>
}
