import { ReactNode } from 'react'
import { createRoot } from 'react-dom/client'

export const mount = (rootElementSelector: string, children: ReactNode) => {
  document.addEventListener('DOMContentLoaded', () => {
    const rootElement = document.querySelector(rootElementSelector)
    if(rootElement){
      const root = createRoot(rootElement)
      root.render(children)
    }
  })
}