import { css } from 'styled-components'

export const responsibleSwap = css`
  &:nth-child(1){
    @media (max-width: 50rem){
      display: none;
    }
  }
  &:nth-child(2){
    display: none;
    @media (max-width: 50rem){
      display: block;
    }
  }
`