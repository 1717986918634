import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Image } from '../components/image'
import { TopBanner } from '../sections/top-banner'
import { MenuBar } from '../sections/menu-bar'
import { NewsSection } from '../sections/news-section'
import { MainSection } from '../sections/main-section'
import { GSection } from '../sections/g-section'
import { MSection } from '../sections/m-section'
import { OmoiSection } from '../sections/omoi-section'
import { Footer } from '../components/footer'
import { ll } from '../styles/ll'
import { RestoreScroll } from '../components/restore-scroll'

const Outer = styled.div`
  position: relative;
  width: 100vw;
  margin-inline: calc((100vw - 100cqw) / 2 * -1);
  display: grid;
  font-family: 'LINESeedJP';
  color: #3D3D3D;
  @media (max-width: 40rem){
    margin-inline: 0;
  }
`

const Content = styled.div`
margin-top: ${ll(92)};
@media (max-width: 40rem){
  
max-width: 100vw;
overflow: clip;
margin-top: ${ll(45)};
}
`

type TopPageProps = {

}
export const TopPage: FunctionComponent<TopPageProps> = () => {
  return <>
    <Outer>
      <RestoreScroll />
      <Content>
        <TopBanner />
        <MenuBar />
        <NewsSection />
        <MainSection />
        <GSection />
        <MSection />
        <OmoiSection />
      </Content>
    </Outer>
  </>
}