import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Image } from '../components/image'
import { ll } from '../styles/ll'
import { absoluteFullWidth, fullWidth, pageMaxWidth } from '../styles/page-max-width'
import { responsibleSwap } from '../styles/responsible-swap'

const Outer = styled.div`
  ${fullWidth}
  position: relative;
  @media (max-width: 50rem){
    padding: 0;
  }
`
const Bg1 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  @media (max-width: 50rem){
    inset: 0;
    margin: 0;
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
    ${responsibleSwap}
  }
`
const Bg2 = styled.div`
  position: relative;
  width: ${ll(1228)};
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 50rem){
    width: 100%;
  }
  img {
    display: block;
    width: 100%;
    height: auto;
    ${responsibleSwap}
  }
`
const Bg3 = styled.div`
  width: ${ll(1228)};
  position: absolute;
  inset: 0;
  margin: auto;
  height: 100%;

  @media (max-width: 50rem){
    width: 100%;
    inset: 0;
    margin: 0;
  }
  img {
    display: block;
    width: 100%;
    height: auto;
    ${responsibleSwap}
  }
`

type TopBannerProps = {

}
export const TopBanner: FunctionComponent<TopBannerProps> = () => {
  return <Outer>
    <Bg1>
      <Image src="top/t-bg1.webp" />
      <Image src="top/t-bg1m.webp" />
    </Bg1>
    <Bg2>
      <Image src="top/t-bg3.webp" />
      <Image src="top/t-bg3m.webp" />
    </Bg2>
    <Bg3>
      <Image src="top/t-bg3.webp" />
      <Image src="top/t-bg3m.webp" />
    </Bg3>
  </Outer>
}
