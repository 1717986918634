import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Image } from '../components/image'
import { ll } from '../styles/ll'
import { responsibleSwap } from '../styles/responsible-swap'
import { Dbr, Mbr } from '../components/br'

const Outer = styled.div`
  background-color: #E7F8FF;
  padding-top: ${ll(120)};
  padding-bottom: ${ll(134)};
  @media (max-width: 50rem){
    padding-top: ${ll(100)};
    margin-top: ${ll(-100)};
    padding-bottom: ${ll(40)};
  }
`

const Inner = styled.div`
  background-color: #fff;
  border-radius: ${ll(8)};
  max-width: ${ll(980)};
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding: ${ll(140)} ${ll(60)} ${ll(40)};
  @media (max-width: 50rem){
    margin-inline: ${ll(10)};
    padding-inline: ${ll(10)};
    padding-top: ${ll(95)};
  }
`

const Head = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: ${ll(-45)};
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  @media (max-width: 50rem){
    margin-left: auto;
    margin-right: auto;
  }
  img {
    display: block;
    width: ${ll(450)};
    height: auto;
    @media (max-width: 50rem){
      width: 95%;
    margin-left: auto;
    margin-right: auto;
    }
  }
`

const H1 = styled.div`
font-size: ${ll(38)};
line-height: normal;
text-align: center;
position: relative;
font-weight: 700;
@media (max-width: 50rem){
  font-size: ${ll(18)};
line-height: normal;
font-weight: 700;
}
&::after {
  content: '';
  display: block;
  height: ${ll(2)};
  width: ${ll(490)};
  margin-left: auto;
  margin-right: auto;
  background-color: #7046B3;
  @media (max-width: 50rem){
    width: 95%;
    margin-top: ${ll(6)};
    
  }
}
`

const Text = styled.div`
font-size: ${ll(16)};
line-height: normal;
margin-top: ${ll(24)};
max-width: ${ll(691)};
margin-inline: auto;
@media (max-width: 50rem){
  font-size: ${ll(15)};
line-height: normal;
margin-top: ${ll(12)};
line-height: 1.5;
}
strong {
  color: #FF4F6B;
}
`

const Img = styled.div`
  margin-top: ${ll(20)};
  position: relative;
  img {
    width: 100%;
    height: auto;
    display: block;
  }
`

const Img1 = styled(Img)`
  max-width: ${ll(635)};
  margin-left: auto;
  margin-right: auto;
  margin-top: ${ll(40)};
  margin-bottom: ${ll(24)};
`
const Img5 = styled(Img)`
  max-width: ${ll(711.03)};
  margin-left: auto;
  margin-right: auto;
  margin-top: ${ll(30)};
  margin-bottom: ${ll(75)};
  @media (max-width: 50rem){
    margin-top: ${ll(13)};
  }
`
const Img6 = styled(Img)`
  max-width: ${ll(300)};
  margin-left: auto;
  margin-right: auto;
  margin-top: ${ll(-30)};
  margin-bottom: ${ll(30)};
  position: relative;
  grid-row: span 2;
  @media (max-width: 50rem){
    max-width: ${ll(100)};
    margin-bottom: 0;
    margin-top: 0;
  }
`
const Img7 = styled(Img)`
  max-width: ${ll(918)};
  margin-left: auto;
  margin-right: auto;
  margin-top: ${ll(-30)};
  margin-bottom: ${ll(30)};
  position: relative;
  @media (max-width: 50rem){
    margin-bottom: 0;
    margin-top: 0;
  }
  img {
    ${responsibleSwap}
  }
`


const ListHead = styled.div`

max-width: ${ll(691)};
font-size: ${ll(20)};
line-height: normal;
padding: ${ll(5)};
margin-top: ${ll(40)};
text-align: center;
margin-left: auto;
margin-right: auto;
background-color: #E7E7E7;
`

const List = styled.div`

max-width: ${ll(691)};
margin-top: ${ll(32)};
margin-left: auto;
margin-right: auto;
display: flex;
flex-direction: column;
gap: ${ll(19)};
`

const ListItem = styled.div`
display: flex;
align-items: center;
white-space: nowrap;
gap: ${ll(12)};
`

const ListItemHead = styled.div`
font-size: ${ll(19)};
line-height: normal;
padding: ${ll(0)} ${ll(10)};
background-color: #00CE9E;
color: white;
`

const ListText = styled.div`
font-size: ${ll(16)};
line-height: normal;
`


const Text2 = styled.div`
  font-size: ${ll(25)};
  line-height: normal;
  font-weight: 700;
  margin-top: ${ll(-40)};
  text-align: center;
  @media (max-width: 50rem){
    font-size: ${ll(18)};
    line-height: normal;
    font-weight: 700;
    line-height: 1.4;
    margin-top: ${ll(-10)};
  }
  strong {
    color: #FF4F6B;
  }
`

const Button = styled.a`
  font-size: ${ll(24)};
  line-height: normal;
  background-color: #FF4F6B;
  font-weight: 700;
margin-top: ${ll(20)};
display: flex;
padding: ${ll(14)} ${ll(50)};
align-items: center;
color: white;
width: fit-content;
margin-left: auto;
margin-right: auto;
border-radius: 100rem;
transition: opacity 0.4s;
&:hover {
  opacity: 0.5;
}
@media (max-width: 50rem){
  font-size: ${ll(18)};
line-height: normal;
font-weight: 700;
padding: ${ll(12)} ${ll(0)};
width: 90%;
text-align: center;
margin-top: ${ll(10)};
justify-content: center;

}
`

const Text8 = styled.div`
  font-size: ${ll(14)};
  line-height: 1.5;
  margin-inline: auto;
  margin-top: ${ll(20)};
  width: fit-content;
  opacity: 0.75;
  @media (max-width: 50rem){
    font-size: ${ll(10)};
  }
`

const Text3 = styled.div`
  font-size: ${ll(14)};
  line-height: normal;
  margin-top: ${ll(20)};
  text-align: center;
  display: none;
  @media (max-width: 50rem){
    display: block;
    font-size: ${ll(11)};
line-height: normal;
margin-top: ${ll(10)};
text-align: left;
margin-bottom: ${ll(35)};
  }
`

const Icon = styled.div`
  font-family: 'FontAwesome';
`

const Img4 = styled.div`
  position: absolute;
  bottom: ${ll(-80)};
  right: ${ll(-20)};
  img {
    display: block;
    width: ${ll(292.97)};
    height: auto;
  }
`

const Text4 = styled.div`
font-size: ${ll(26)};
line-height: normal;
text-align: center;
font-weight: 700;
color: #0097F1;
@media (max-width: 50rem){
  font-size: ${ll(18)};
line-height: normal;
font-weight: 700;
margin-top: ${ll(-25)};
}
`

const Text5 = styled.div`
font-size: ${ll(16)};
line-height: normal;
text-align: center;
@media (max-width: 50rem){
  font-size: ${ll(15)};
line-height: normal;
text-align: left;
}
`

const Hr1 = styled.div`
  margin-top: ${ll(80)};
  display: grid;
  grid-template-columns: ${ll(459)} ${ll(316)};
  gap: ${ll(33)};
  justify-content: center;
  @media (max-width: 50rem){
    grid-template-columns: 1fr;
    margin-top: ${ll(50)};
  }
`

const Hr1I = styled.div`
font-size: ${ll(16)};
line-height: normal;
@media (max-width: 50rem){
  font-size: ${ll(15)};
line-height: normal;
}
  img {
    display: block;
    width: 100%;
    height: auto;
  }
  &:nth-child(2){
    @media (max-width: 50rem){
      display: flex;
      margin-top: ${ll(-20)};
    }
    img {
      width: ${ll(210)};
      height: auto;
      display: block;
      margin-left: auto;
      margin-top: ${ll(-20)};
      object-fit: contain;
      @media (max-width: 50rem){
        width: ${ll(120)};
      }
    }
  }
`
const Hr2 = styled.div`
  margin-top: ${ll(65)};
  display: grid;
  grid-template-columns: ${ll(459)} ${ll(316)};
  gap: ${ll(33)};
  justify-content: center;
  @media (max-width: 50rem){
    grid-template-columns: 1fr;
    gap: ${ll(0)};
  }
`

const Hr2I = styled.div`
font-size: ${ll(16)};
line-height: normal;
  img {
    display: block;
    width: ${ll(286)};
    height: auto;
    @media (max-width: 50rem){
      width: 70%;
      margin-left: auto;
      margin-right: auto;
    }
  }
`

const Text6 = styled.div`
font-size: ${ll(26)};
line-height: normal;
color: #0097F1;
font-weight: 700;
@media (max-width: 50rem){
  font-size: ${ll(18)};
line-height: normal;
font-weight: 700;
text-align: center;
margin-top: ${ll(-30)};
}
`
const Text7 = styled.div`
font-size: ${ll(16)};
line-height: normal;
margin-top: ${ll(15)};
@media (max-width: 50rem){
  font-size: ${ll(15)};
line-height: normal;
line-height: 1.5;
}
`

const L1 = styled.div`
width: ${ll(720)};
box-sizing: border-box;
padding: ${ll(9)};
background-color: #F0F6F8;
margin-left: auto;
margin-right: auto;
margin-top: ${ll(20)};
@media (max-width: 50rem){
  width: 100%;
}
`

const L1Inner = styled.div`
  padding: ${ll(26)};
  border: 1px dashed #B9B9B9;
`
const L1Text = styled.div`
font-size: ${ll(14)};
line-height: normal;
text-align: center;
a {
  color: #0035A1;
  text-decoration: underline;
}
`

const C1 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${ll(20)};
  margin-block: ${ll(30)};

  @media (max-width: 50rem){
    grid-template-columns: auto 1fr;
  }
`

const C1I1 = styled.div`
`

const C1I2 = styled.div`
grid-column: 2;
@media (max-width: 50rem){
  grid-column: span 2;
}
`

type MSectionProps = {

}
export const MSection: FunctionComponent<MSectionProps> = () => {
  return <Outer id="m">
    <Inner>
      <Head>
        <Image src="top/d1.webp" />
      </Head>
      <H1>
      世界初の認知リスク検査
      </H1>
      <Text>
      メタロ・バランス 認知リスク検査は、大阪府の大阪精神医療センターとの共同研究で誕生しました。２０２４年９月から全国の提携医療機関で検査を受けることが出来ます。<br />
      ※検査会場によっては、検査を実施していない場合がございます。
      </Text>
      {/*<Img1>
        <Image src="top/d2.webp" />
      </Img1>*/}
      <Text4>
      MCI（軽度認知障害）
      </Text4>
      <Text5>
      突然認知症になることはありません。MCIという中間状態から始まり、その後に認知症に至ります。<br />
      MCIの段階で対処すれば、回復・進行停止の可能性がありますが、認知症になってしまうと健常に戻ることは大変難しくなります。
      </Text5>
      <Img5>
        <Image src="top/d3.webp" />
      </Img5>
      <C1>
        <Img6>
          <Image src="top/d8-1.webp" />
        </Img6>
        <C1I1>
          <Img7>
            <Image src="top/d8-2.webp" />
            <Image src="top/d8m-2.webp" />
          </Img7>
        </C1I1>
        <C1I2>
          <Text2>
            がんリスク検査と同時に受けると<br /><strong>採血も１回のみ</strong>で簡単<Dbr />
            <strong>特別割引価格</strong>でご提供！
          </Text2>
          <Button href="/entry">
            検査申し込みはこちら <Icon></Icon>
          </Button>
          <Text8>
          ※検査会場によっては、検査実施していない場合がございます。あらかじめご了承ください。
          </Text8>
        </C1I2>
        
      </C1>
      <H1>
      認知症について
      </H1>
      <Text>
        認知症は、一人暮らしが困難なほど認知機能が低下した状態です。<br />
        MCIは、認知機能は低下しているが、日常生活は、正常に送ることができるという状態です。<br />
        MCIは、認知症の一歩手前の段階で、MCIの人が必ず認知症になってしまうわけではありません。<br />
        MCIの方が認知症に移行する割合は、1年後10％、5年後50％となりますが、MCIの段階で適切な対処をすれば、健常な状態に回復する可能性があります。また、65歳以上の方の15％がMCIであるという研究もあります。
      </Text>
      <Hr1>
        <Hr1I>
          <Image src="top/d5.webp" />
        </Hr1I>
        <Hr1I>
          認知症の原因疾患はさまざまです。<br />
          最も多いのは、アルツハイマー型認知症で、認知症の半数以上を占めています。<br />
          次いで血管性認知症、レビー小体型認知症、その他です。
        </Hr1I>
      </Hr1>
      <Hr2>
        <Hr2I>
          <Text6>
          普段から生活習慣にご注意ください
          </Text6>
          <Text7>
            ・糖尿病は、アルツハイマー型認知症のリスクが2.1倍に。<br />
            ・低血糖になると認知症になる割合が1.7倍に。<br />
            ・脳卒中の人は、10人に1人が1年以内に認知症に。<br />
            ・中年期では、太っているほど認知症になりやすい。<br />
            ・中年期に総コレストロール値が高いと認知症になりやすい。<br />
            ・たばこは控え、お酒は適度に。<br />
            ・定期的な運動習慣は、認知症予防になる。<br />
            ・運動は認知機能向上に効果がある。
          </Text7>
        </Hr2I>
        <Hr2I>
          <Image src="top/d7.webp" />
        </Hr2I>
      </Hr2>
      <L1>
        <L1Inner>
          <L1Text>
            【MCI・認知症の理解の為に】<br />
            国立研究開発法人 国立長寿医療研究センターの冊子MCIハンドブックをご覧ください。<br />
            <br />
            <a href="https://www.mhlw.go.jp/content/001100282.pdf" target="_blank" rel="noopener noreferrer">認知症情報ポータル  関連パンフレット</a>
          </L1Text>
        </L1Inner>
      </L1>
    </Inner>
  </Outer>
}
